import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const PageHeading = ({ children }) => {
  return (
    <div className="flex flex-col items-center pt-2">
      <div className="mr-2">
        <StaticImage
          layout="fixed"
          formats={["auto", "webp", "avif"]}
          src="../images/profilepic.png"
          width={120}
          quality={95}
          alt="Profile picture"
        />
      </div>
      {children}
    </div>
  )
}

export default PageHeading
