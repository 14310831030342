import * as React from "react"
import { Link, graphql } from "gatsby"
import PageHeading from "../../components/page-heading"

import Bio from "../../components/bio"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="jAsOnFrItScHe" />
        <p>No blogs here...</p>
        <Bio />
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Blog" />
      <div className="flex">
        <div className="m-auto w-10/12 md:w-3/4">
          <PageHeading>
            <p className="font-nunito text-xl">
              This is where I'll be documenting my developer journey going
              forward. Thanks for stopping by.
            </p>
          </PageHeading>
        </div>
      </div>
      <ol
        style={{ listStyle: `none` }}
        className="flex flex-col items-center ml-2 mt-3"
      >
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li
              key={post.fields.slug}
              className="w-10/12 mb-7 md:w-2/3 drop-shadow-2xl p-1 hover:shadow-2xl bg-white hover:bg-zinc-500 rounded-lg dark:bg-zinc-400"
            >
              <Link to={"/blog" + post.fields.slug} itemProp="url">
                <article
                  className="flex flex-col w-10/12"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2 className="text-2xl font-semibold font-nunito text-slate-800 dark:text-slate-900">
                      <span itemProp="headline">{title}</span>
                    </h2>
                    <small className="font-nunito text-slate-800">
                      {post.frontmatter.date}
                    </small>
                  </header>
                  <section>
                    <p
                      className="font-nunito text-lg text-slate-800"
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                      itemProp="description"
                    />
                  </section>
                </article>
              </Link>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
